<template>
  <div id="nav">
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  </div>
  <router-view/>
</template>

<script lang="ts" setup>



</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  // padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

h1, h2, h3, span, b, div{
  $val:220;
  color:rgb($val,$val,$val);
  margin:0;
}

// .el-date-range-picker__content{
//   display: flex;
//   align-content: space-between;
// }
// .el-date-range-picker__content.is-right{
//   display: none;
// }
// .el-date-range-picker__content.is-left{
//   display: none;
// }
</style>
