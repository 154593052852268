import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// ElementPlus ##################################################
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// icon
import {Edit,ArrowRight,ArrowLeft,
  ArrowRightBold,ArrowLeftBold,ArrowDownBold,ArrowUpBold,
  DArrowRight,DArrowLeft,Plus,Check,Fold,Upload,Delete} from '@element-plus/icons-vue'





const app = createApp(App);
app.use(store).use(router)
.use(ElementPlus)
.mount('#app')
// element-plus icon
app.component('Edit',Edit)
app.component('ArrowRight',ArrowRight)
app.component('ArrowLeft',ArrowLeft)
app.component('ArrowRightBold',ArrowRightBold)
app.component('ArrowLeftBold',ArrowLeftBold)
app.component('ArrowDownBold',ArrowDownBold)
app.component('ArrowUpBold',ArrowUpBold)
app.component('DArrowRight',DArrowRight)
app.component('DArrowLeft',DArrowLeft)
app.component('Plus',Plus)
app.component('Check',Check)
app.component('Fold',Fold)
app.component('Upload',Upload)
app.component('Delete',Delete)

