import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store/index'
import axios from 'axios'

// state management ####################
const backenIp=store.state.backenIp

let now = new Date()

const routes: Array<RouteRecordRaw> = [  
  // login #########################################
  {
    path: '/login',
    name: 'login',
    component: ()=>import('@/components/login.vue')
  },
  // if token is valid #############################
  {
    path: '/',
    component: () => import('../components/mainLayout.vue'),
    children:[
      {
        path: '/',
        redirect: '/expense/'+now.getFullYear()+'/'+String(now.getMonth()+1) 
      },
      {
        path: '/expense/:year/:month',
        name: 'expense',
        component: ()=>import('@/components/home.vue'),
      },
      {
        path: '/duration',
        name: 'duration',
        component: ()=>import('@/components/duration.vue')
      },

      // 404 page ##############
      {
        path:'/:pathMatch(.*)*',
        component:()=>import('../components/404.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// check if the token in localStorage is valid ###############
router.beforeEach((to, from) => {     
  // refresh token #######################
  axios({
    method: 'post',
    url:backenIp+'/api/token/refresh/',
    data: {      
      refresh:localStorage.getItem('authTokenRefresh')
    },
  })
  .then(res=>{
    if(res.status==200){
      // restore new token to cookie ###
      store.commit('setToken',res.data)    
    }
    else{
      router.push('/login')
    }
  }) 
  // verify token #####################
  // if valid -> go to 'to'
  // if not -> go to '/login'
  .then(res=>{
    axios({
      method: 'post', 
      url:backenIp+'/api/token/verify/',
      data: {      
        token:localStorage.getItem('authTokenAccess')
      },
    })
    .then(res=>{    
      // console.log(res)
      // if token valid #############
      if(res.status==200){      
        router.push(to)                  
      }
      else{
        // if token is not valid ####
        router.push('/login')
      }
    })
    .catch(err=>{
      // if token is not valid ######
      router.push('/login')
    })
  })
  .catch(err=>{
    // if token is not valid ######
    router.push('/login')
  })
})

export default router

   
