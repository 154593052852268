import { createStore } from 'vuex'
import backendIpSetting from '@/../backendIp.json'

export default createStore({
  state: {
    backenIp:backendIpSetting['ip'],
    authTokens: localStorage.getItem('authTokens') || {'refresh':null,'access':null}

  },

  mutations: {
    setToken (state,newAuthTokens) {
      state.authTokens =newAuthTokens
      localStorage.setItem("authTokenRefresh",newAuthTokens['refresh']);
      localStorage.setItem("authTokenAccess",newAuthTokens['access']);
    },

    delToken (state) {
      state.authTokens = {'refresh':null, 'access':null};
      localStorage.removeItem("authTokenRefresh")
      localStorage.removeItem("authTokenAccess")
    },

    
  },
  actions: {
  },
  modules: {
  }
})
